import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
          <div className="container">
            <div className="">
              <a href="/">
                <img className="logo" src="img/logo.png" alt="" />
              </a>
            </div>
          </div>
        </header>
      );
    }
  }

  export default Header;