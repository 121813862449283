import React, { Component } from 'react';

class Footer extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <footer>
          <div className="container">
            <img className="footer_logo" src="img/logo.png" alt="" />
            <hr />
            <p>&copy; 2021 maticbusiness All Rights Reserved. </p>
          </div>
        </footer>
      );
    }
  }

  export default Footer;