import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MaticBusiness from "../../contracts/MaticBusiness.json";
import getWeb3 from "../../getWeb3";
const defaultAmountArr = ['50','100','250','500','1000'];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},
      TotalBonus:0,
      totalWithdrawn:0,
      totalDeposits:0,
      totalInvested:0,
      totalUsers:0,
      networkMain:false,
      YourAddress: '-',
      YourAddressfull: null,
      amountBNB:null,
      user_referer:'-----------',
      balance:null,
      adminFee:null,
      adminFee_percentage:5,
      reinvest_percentage:0,
      withdrwal_percentage:0,
      //GetDownlineIncomeByUserId:0,
      //GetUplineIncomeByUserId:0,
      communityLevels:[],
      sponsorList:[],
      poolDepositTotal:0,
      poolDeposit:0,
      poolQualifierCount:0,
      GetPoolIncome:0,
      GetPoolIncomeTwo:0,
      _userUplineIncome:0,
      _userDownlineIncome:0,
      poolDepositTotalTwo:0,
      poolDepositTwo:0,
      poolQualifierCountTwo:0,
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = MaticBusiness.networks[networkId];
        const instance = new web3.eth.Contract(
          MaticBusiness.abi,
          deployedNetwork && deployedNetwork.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;

    console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    let userInfo = await contract.methods.users(accounts).call();    
    userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
    userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
    userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
    userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
    userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
    userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
    let user_referer = '---------';
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      user_referer = userInfo.referrer;
      user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
    }

    let GetPoolIncome = await contract.methods.GetPoolIncome(accounts).call();
    GetPoolIncome = this.state.web3.utils.fromWei(GetPoolIncome[0], 'ether');
    GetPoolIncome = parseFloat(GetPoolIncome).toFixed(5);

    let GetPoolIncomeTwo = await contract.methods.GetPoolIncomeTwo(accounts).call();
    GetPoolIncomeTwo = this.state.web3.utils.fromWei(GetPoolIncomeTwo[0], 'ether');
    GetPoolIncomeTwo = parseFloat(GetPoolIncomeTwo).toFixed(5);
    
    let TotalBonus = await contract.methods.TotalBonus(accounts).call();
    TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
    TotalBonus = parseFloat(TotalBonus).toFixed(5);
    let totalWithdrawn = await contract.methods.totalWithdrawn().call();
    totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
    totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
    let totalDeposits = await contract.methods.totalDeposits().call();
    totalDeposits = parseFloat(totalDeposits).toFixed(5);
    let totalInvested = await contract.methods.totalInvested().call();
    totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
    totalInvested = parseFloat(totalInvested).toFixed(5);
    let totalUsers = await contract.methods.totalUsers().call();

    let poolDepositTotal = await contract.methods.poolDepositTotal().call();
    poolDepositTotal = this.state.web3.utils.fromWei(poolDepositTotal, 'ether');
    poolDepositTotal = parseFloat(poolDepositTotal).toFixed(5);

    let poolDepositTotalTwo = await contract.methods.poolDepositTotalTwo().call();
    poolDepositTotalTwo = this.state.web3.utils.fromWei(poolDepositTotalTwo, 'ether');
    poolDepositTotalTwo = parseFloat(poolDepositTotalTwo).toFixed(5);

    let poolDeposit = await contract.methods.poolDeposit().call();
    poolDeposit = this.state.web3.utils.fromWei(poolDeposit, 'ether');
    poolDeposit = parseFloat(poolDeposit).toFixed(5);

    let poolDepositTwo = await contract.methods.poolDepositTwo().call();
    poolDepositTwo = this.state.web3.utils.fromWei(poolDepositTwo, 'ether');
    poolDepositTwo = parseFloat(poolDepositTwo).toFixed(5);

    let poolQualifierCount = await contract.methods.poolQualifierCount().call();
    let poolQualifierCountTwo = await contract.methods.poolQualifierTwoCount().call();
    

    let balance = TotalBonus;
    let adminFee = balance*this.state.adminFee_percentage/100;
    balance = balance - adminFee;


    let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
    let reinvest_percentage = getEligibleWithdrawal.reivest;
    let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

    let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
    let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

    // let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
    // GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
    // GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
    // GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

    // let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
    // GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
    // GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
    // GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

    let _userUplineIncome = await contract.methods._userUplineIncome(accounts).call();
    _userUplineIncome = this.state.web3.utils.fromWei(_userUplineIncome, 'ether');
    _userUplineIncome = _userUplineIncome - singleUplineBonusTaken;
    _userUplineIncome = parseFloat(_userUplineIncome).toFixed(5);

    let singleDownlineBonus = this.state.web3.utils.fromWei(userInfo.singleDownlineBonus, 'ether');
    let _userDownlineIncome = singleDownlineBonus - singleDownlineBonusTaken;
    _userDownlineIncome = parseFloat(_userDownlineIncome).toFixed(5);

     
    this.setState({ 
      userInfo,
      TotalBonus,
      totalWithdrawn,
      totalDeposits,
      totalInvested,
      totalUsers,
      poolDepositTotal,
      poolDepositTotalTwo,
      poolDeposit,
      poolDepositTwo,
      poolQualifierCount,
      poolQualifierCountTwo,
      GetPoolIncome,
      GetPoolIncomeTwo,
      //networkMain:true,
      YourAddress:YourAddress,
      YourAddressfull:YourAddressfull,
      user_referer:user_referer,
      balance,
      adminFee,
      reinvest_percentage,
      withdrwal_percentage,
      //GetUplineIncomeByUserId,
      //GetDownlineIncomeByUserId,
      _userUplineIncome,
      _userDownlineIncome,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      // let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
      let uplineCount = 30;//getEligibleLevelCountForUpline.uplineCount;
      let downlineCount = 20;//getEligibleLevelCountForUpline.downlineCount;
      let communityLevels = [];
      let upline_users = [];
      let downline_users = [];
      let current_user = accounts;
      let userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=uplineCount;i++){          
        if(current_user == userInfo.singleUpline) continue;
        current_user = userInfo.singleUpline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
      }
      upline_users.sort((a, b) => b.level>a.level? 1 : -1);
      upline_users.map(function(val, index){
        communityLevels.push(val);
      });
      let currentUserInfo = await contract.methods.users(accounts).call();
      let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
      let income = (investment/100).toFixed(5);
      income = (0).toFixed(5);
      investment = parseFloat(investment).toFixed(5);
      let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
      communityLevels.push(main_user);
      current_user = accounts;
      userInfo = await contract.methods.users(current_user).call();
      for(let i=1;i<=downlineCount;i++){
        if(current_user == userInfo.singleDownline) continue;
        current_user = userInfo.singleDownline;
        let emptyAddress = /^0x0+$/.test(current_user);
        if(emptyAddress) continue;
        userInfo = await contract.methods.users(current_user).call();
        let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
      }
      downline_users.map(function(val, index){
        communityLevels.push(val);
      });

      let sponsorList = [];
      let count = 0;
      for(let i=0;i<11;i++){
        let referral_stage = await contract.methods.referral_stage(accounts,i).call();
        let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
        //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
        let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
        _investment = parseFloat(_investment).toFixed(5);
        percentage_amount = parseFloat(percentage_amount).toFixed(5);
        let _noOfUser = referral_stage._noOfUser;
        
        if(i == 0){
          count = _noOfUser;
        }
        
        let status = '';
        let requireText = '';
       
        if((i > 1 && i < 7) && count < 5){
          status = 'Unqualified';
          requireText = 'require '+4 + ' direct';
        }else
        if(i > 6 && count < 8){
          status = 'Unqualified';
          requireText = 'require '+8 + ' direct';
        }
        sponsorList.push({requireText:requireText,status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
      }
      
      //this.setState({sponsorList});
      this.setState({communityLevels, sponsorList});
      // console.log(communityLevels);      
      // console.log(sponsorList);       
    });     
  }

  doJoinNow = async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    let userInfo = await contract.methods.users(accounts).call();
    if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
      sponsor_address = userInfo.referrer;
    }
    else if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amountBNB){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.amountBNB;
    if(sponsor_address){
      if(balanceEthVal >= amountBNB){
        try {
          this.setState({loading:true});
          this.state.contract.events.NewDeposit((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
            }
          });

          let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
          let invest = await this.state.contract.methods.invest(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Insufficient Balance!",{ variant: 'error' });          
      }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doWithdrawal = async () => {
    if(this.state.TotalBonus > 0){
      this.setState({loading:true});
      this.state.contract.events.Withdrawn((err, eventResult) => {
        console.log('eventResult',eventResult);
        console.log('err',err);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!',{ variant: 'error' });              
        }        
      });

      let withdrawal = await this.state.contract.methods.withdrawal().send(
        { 
          from: this.state.accounts
        }
      );
      //console.log('withdrawal',withdrawal); 
    }else{
      this.props.enqueueSnackbar('Insufficient balance!',{ variant: 'error' });
    }
         
  }

  render() {
    return (
      <Frame withHeader={true} withFooter={true}>
        {/* <div className="text-right pt_20">
          <a className="top_grad_btn account_mobile_btn" href="/account-summary">Account summary <i className="fa fa-long-arrow-right"></i></a>
        </div> */}


<div className="modal fade show" id="Modal_popup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" data-keyboard="false" data-backdrop="static" aria-modal="true">
  <div className="modal-dialog modal-lg modal_margin_t">
    <div className="modal-content">
      <button type="button" className="close modal_close" data-dismiss="modal">
        <img className="modal_close_img" src="img/Close-icon.png" alt="" />
      </button>
      <div className="modal_head text-center">
        <img src="img/logo.png" alt="" />
      </div>
      <div className="modal-body modal_body_padding">
       <p><strong>Packages</strong></p>
       <h6 className="white_text">50/100/250/500/1000</h6>
       <p>Matic coin</p>
       <p>Based over a polygon chain, a 100% distribution algorithm,working on a contribution method.</p>
       <p>There <span className="white_text"> 4 </span> ways to contribute and receive contribution</p>
       <h5>Income 1</h5>
       <p><strong>Global single leg.</strong></p>
       <p>Everyone who joins to global tree comes under each other and contribute to
unknown people, The people who joins earlier than me called upline and the
people who joins after me called downline And we receive contribution from <span className="white_text">20</span>
people upline and <span className="white_text">30</span> people downline Each one contributes <span className="white_text">1%</span> of their package to
in up and downline</p>
       <p>Each one contributes<span className="white_text"> 1%</span> of their package to in up and downline</p>
       <h5>Income 2</h5>
       <p>There is level income, which works only from your and your team network people, we earn from <span className="white_text">11</span> level down, from first level <span className="white_text">10%</span> and <span className="white_text">2nd</span> to <span className="white_text">11th</span> level <span className="white_text">3%</span> each...</p>
       <p>Level income</p>
       <p>1. <span className="white_text">10%</span> 2. <span className="white_text">3%</span> 3. <span className="white_text">3%</span> 4. <span className="white_text">3%</span> 5. <span className="white_text">3%</span> 6. <span className="white_text">3%</span> 7. <span className="white_text">3%</span> 8. <span className="white_text">3%</span> 9. <span className="white_text">3%</span> 10. <span className="white_text">3%</span> 11. <span className="white_text">3%</span></p>
       <p>Condition to achieve level income</p>
       <p><span className="white_text">1st</span> and <span className="white_text">2nd</span> level are open by default</p>
       <p>Minimum 4 direct sponsors to open from <span className="white_text">3rd,4th,5th,6th and 7th</span> level. <span className="white_text">8</span> direct to open <span className="white_text">8th,9th 10th and 11th</span> level</p>
       <h5>Income 3</h5>
       <p>There is pool of <span className="white_text">5%</span> from total contribution daily basis, all pool qualifiers receive equal contribution till the part of pool</p>
       <p>Condition to qualify pool</p>
       <p>Need to complete <span className="white_text">4</span> direct sponsors within <span className="white_text">4</span> days of your account activation and remain in the pool till <span className="white_text">1000</span> Matic has been earned by you through any method of income...</p>
       <h5>Income 4</h5>
       <p>Another pool of <span className="white_text">5%</span> from total turn over and equal distribution method</p>
       <p>Condition to qualify</p>
       <p>There should be <span className="white_text">500</span> contributors at 11th level of your tree and remain in the pool for foreve</p>
       <p>So this is <span className="white_text">100%</span> distribution, entire code has written over polygon smart contract, <span className="white_text">100%</span> decentralised,</p>
       <p>no modification,</p>
       <p>no alterations,</p>
       <p>no manipulation.</p>
       <p>Reinvestment/ withdrawal</p>
       <h5>condition 1</h5>
       <p>No direct partner required...</p>
       <p>package value <span className="white_text">50</span> to <span className="white_text">249</span> Matic coin</p>
       <p><span className="white_text">60%</span> reinvestment,</p>
       <p><span className="white_text">40%</span> wallet withdrawal</p>
       <h5>Condition 2</h5>
       <p><span className="white_text">4</span> direct partner required</p>
       <p><span className="white_text">250</span> matic to <span className="white_text">499</span> matic</p>
       <p><span className="white_text">50%</span> reinvestment and</p>
       <p><span className="white_text">50%</span> wallet withdrawal</p>
       <h5>Condition 3</h5>
       <p><span className="white_text">2</span> direct partner required</p>
       <p>Personal package should in between <span className="white_text">500</span> matic to <span className="white_text">999</span> matic</p>
       <p><span className="white_text">40%</span> reinvestment and</p>
       <p><span className="white_text">60%</span> wallet withdrawal</p>
       <h5>Condition 4</h5>
       <p>No direct partner required</p>
       <p>Your personal package should be</p>
       <p><span className="white_text">1000</span> matic or above</p>
       <p><span className="white_text">30%</span> reinvestment and</p>
       <p><span className="white_text">70%</span> wallet withdrawal</p>
       <p>And reinvestment will be distributed as per package purchased method.</p>
       <p>Deduction <span className="white_text">5%</span></p>
       <p>Enjoy <span className="white_text">100%</span> trust and transparent.</p>
       <hr />
       <p className="text-right mb-0"><a className="white_text" href="www.maticbusiness.com">www.maticbusiness.com</a></p>
      </div>

    </div>
  </div>
</div>



        {this.state.loading ? (
          <div className="loader-container">
            <div className="loader">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        ) : (null)}

        <div className="pb_60">
          <div className="container">            
            <div className="banner_box text-center">
              <h4>Stake &amp; Earn!</h4>
              <h2>Decentralized Investment Platform!</h2>
              <a target="_blank" className="button_icon mt_40" href="https://polygonscan.com/address/0xFE86e1653158A48F0B36B643Ff1D5fEE239bBc8B">Verified Contract <span><i className="fa fa-angle-right"></i></span></a>
              <div>
                <a target="_blank" className="popup-youtube" href="video/Matic-Business1.mp4"><img className="video-icon" src="img/video-icon.png" alt="" /></a>
               </div>
            </div>
          </div>
        </div>
{/*
        <div className="pb_60">
          <div className="container">
            <div className="row cus_row">
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Total Deposit</h4>
                  <h5>{this.state.userInfo.amount} MATIC</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-6">
                <div className="Personal_Details_inner">
                  <h4>Total Withdrawn</h4>
                  <h5>{this.state.userInfo.totalWithdrawn} MATIC</h5>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-12">
                <div className="Personal_Details_inner">
                  <h4>Referred By</h4>
                  <h5>{this.state.user_referer}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
*/}
        <div className="pb_30">
          <div className="container">
            <div className="sm_container">
              <div className="sm_container_bg">
                <div className="sm_container_padding">
                  <div className="all_heading text-center">
                    <h3>Join with <span>Us now</span></h3>
                  </div>
        
                  <div className="form-group mb_20">
                    {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                      <input className="cus_input" type="text" placeholder="Sponsor Address"
                        readOnly
                        value={this.state.user_referer}
                        onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                      />
                    ):(
                      <input className="cus_input" type="text" placeholder="Sponsor Addresss"
                        value={this.state.sponsor_address}
                        onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                      />
                    )}
                  </div>
                  <ul className="trx_btn">
                    {defaultAmountArr.map((amount,key) => {return(
                      <li key={key}>
                        <button className={this.state.amountBNB==amount?'active':'inactive'}
                          onClick={e => this.setState({amountBNB:amount})}>
                          {amount+' MATIC'}
                        </button>
                      </li>
                      )                              
                    })}
                  </ul>
                  <div className="text-center mt_40">
                    <button onClick={this.doJoinNow} className="button_icon">Join Now <span><i className="fa fa-angle-right"></i></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
            <div className="sm_heading">
              <h5>ID - {this.state.YourAddress}</h5>
            </div>
            <ul className="Community_ul">
              <li>
                <div>
                  <h4>Community Level</h4>
                  <p>Upline Income</p>
                  <h5>{this.state._userUplineIncome} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Community Level</h4>
                  <p>Downline Income</p>
                  <h5>{this.state._userDownlineIncome} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Affiliate Level</h4>
                  <p>Sponsor Income</p>
                  <h5>{this.state.userInfo.referrerBonus} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Pool</h4>
                  <p>Pool Income 1</p>
                  <h5>{this.state.GetPoolIncome} MATIC</h5>
                </div>
              </li>
              <li className="width_100">
                <div>
                  <h4>Pool</h4>
                  <p>Pool Income 2</p>
                  <h5>{this.state.GetPoolIncomeTwo} MATIC</h5>
                </div>
              </li>
            </ul>  
            <ul className="Community_ul">
              <li className="width_100">
                <div>
                  <h4>Referred By</h4>
                  <h5>{this.state.user_referer}</h5>
                </div>
              </li>  
            </ul>          
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
            <div className="all_heading text-center">
              <h3>Global  <span>Details</span></h3>
            </div>
            <ul className="Community_ul">
              {/*
              <li>
                <div>
                  <h4>Total Community</h4>
                  <h5>{this.state.totalUsers}</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Total Invested</h4>
                  <h5>{this.state.totalInvested} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Total Withdrawal</h4>
                  <h5>{this.state.totalWithdrawn} MATIC</h5>
                </div>
              </li>
              */}
              <li>
                <div>
                  <h4>Total Pool 1</h4>
                  <h5>{this.state.poolDepositTotal} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Daily Pool 1</h4>
                  <h5>{this.state.poolDeposit} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Pool Qualifiers 1</h4>
                  <h5>{this.state.poolQualifierCount}</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Total Pool 2</h4>
                  <h5>{this.state.poolDepositTotalTwo} MATIC</h5>
                </div>
              </li>
              <li>
                <div>
                  <h4>Daily Pool 2</h4>
                  <h5>{this.state.poolDepositTotalTwo} MATIC</h5>
                </div>
              </li>
              <li className="width_100">
                <div>
                  <h4>Pool Qualifiers 2</h4>
                  <h5>{this.state.poolQualifierCountTwo}</h5>
                </div>
              </li>
            </ul>          
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
            <div className="sm_container">
              <div className="sm_container_bg">
                <div className="sm_container_padding">
                  <div className="all_heading text-center">
                    <h3>WALLET <span>BALANCE</span></h3>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Available MATIC</label>
                        <input className="cus_input" type="text" readOnly value={this.state.balance} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <div className="form-group">
                        <label>Reinvestment Amount (MATIC)</label>
                        <input className="cus_input" type="text" readOnly 
                          value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5)} />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                      <div className="form-group">
                        <label>Withdrawable Amount (MATIC)</label>
                        <input className="cus_input" type="text" readOnly
                          value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5)} />
                      </div>
                    </div>
                  </div>
                  <p className="mb-0">Reinvestment percentage : <strong>{this.state.reinvest_percentage}%</strong></p>
                  <p>Withdrawable percentage : <strong>{this.state.withdrwal_percentage}% </strong></p>
                  <div className="text-center mt_40">
                    <button onClick={this.doWithdrawal} className="button_icon">Submit <span><i className="fa fa-angle-right"></i></span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="all_heading text-center">
                <h3>Community <span>Level</span></h3>
              </div>
              <div className="teble-responsive table-fixed">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="col-3">Level</th>
                      <th className="col-6">User ID</th>
                      <th className="col-3">Investment</th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {this.state.communityLevels.length ? (
                      this.state.communityLevels.length>0 ? (
                        this.state.communityLevels.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td className="col-3">{val.levelText}</td>
                              <td className="col-6">{val.username}</td>
                              <td className="col-3">{val.investment} MATIC</td>
                              {/* <td className="col-3">{val.income} MATIC</td> */}
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )}  
                  </tbody>
                </table>
              </div>        
            </div>
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
            <div className="md_container">
              <div className="all_heading text-center">
                <h3>My sponsor <span>List</span></h3>
              </div>
              <div className="teble-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th> Level </th>
                      <th> Count </th>
                      <th> Invest Amount </th>
                      <th> Amount </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sponsorList.length ? (
                      this.state.sponsorList.length>0 ? (
                        this.state.sponsorList.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`sl${index}`} className={class_name}>
                              <td className="col-3"> Level-{val.level} <span style={{color:'#96d5cf'}}>{val.requireText?(`(${val.requireText})`):(null)}</span></td>
                              <td className="col-3">{val._noOfUser}</td>
                              <td className="col-3">{val._investment} MATIC</td>
                              <td className="col-3">{val.percentage_amount} MATIC {val.status?(`(${val.status})`):(null)}</td>
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>        
            </div>
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
            <div className="sm_container">
              <div className="sm_container_bg">
                <div className="sm_container_padding">
                  <div className="all_heading text-center">
                    <h3>Your Referral <span>Link</span></h3>
                  </div>
                  <h4 className="referal_text word-break">https://maticbusiness.com/{this.state.YourAddress}</h4>
                
                  <div className="text-center mt_40">
                    <CopyToClipboard text={`https://maticbusiness.com/${this.state.YourAddressfull}`}
                      onCopy={() => this.props.enqueueSnackbar("Copied Successfully!",{ variant: 'info' })
                      }>
                      <button className="button_icon">Copy Link <span><i className="fa fa-angle-right"></i></span></button>
                    </CopyToClipboard>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb_40">
          <div className="container">
              <div className="all_heading text-center">
                <h3>PLAN  <span>VIDEO</span></h3>
              </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div class="video_inner">
                  <a target="_blank" class="popup-youtube" href="video/Matic-Business1.mp4">
                    <img class="video-img" src="img/video_bg1.jpg" alt="" />
                    <img className="video-icon" src="img/video-icon.png" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-6">
                <div class="video_inner">
                  <a target="_blank" class="popup-youtube" href="video/Matic-Business2.mp4">
                    <img class="video-img" src="img/video_bg2.jpg" alt="" />
                    <img className="video-icon" src="img/video-icon.png" alt="" />
                  </a>
                </div>
              </div>
             
            </div>

          </div>
        </div>
        
        <div className="pb_40">
          <div className="container">
            <ul className="pdf_ul">
              <li>
                <a target="_blank" href="pdf/Matic-Business.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">English PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_Chinese.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">Chinese PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_French.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">French PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_German.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">German PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_Indonesian.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">Indonesian PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_Japanese.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">Japanese PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_Russian.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">Russian PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_Tagalog.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">Tagalog PDF</span>
                </a>
              </li>
              <li>
                <a target="_blank" href="pdf/Matic-Business_Vietnamese.pdf">
                  <span className="span_1"><img src="img/pdf_download.png" alt="" /></span>
                  <span className="span_2">Vietnamese PDF</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        
      </Frame>
    );
  }
}

const useStyles = {
  root: {
    flexGrow: 1
  }
}

export default withRouter(withStyles(useStyles)(withSnackbar(Home)));